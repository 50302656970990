import React from 'react';
import logo from './assets/logo.svg';
import lines from './assets/lines.png';
import './App.css';
import {
    Box,
    Center,
    Container,
    Flex, GridItem,
    Heading,
    Image, Link,
    SimpleGrid, Spacer,
    Text,
} from "@chakra-ui/react";

function App() {
  return (
          <Box className="App" py='20px'>
              <Flex>
                  <Image className='rotated' src={lines} height='50px'/>
              </Flex>
              <Flex>
                  <Center m='auto'>
                      <Image src={logo} alt="logo" height='170px' />
                  </Center>
              </Flex>
              <Container maxW='400px' m='auto' p='50px'>
                  <Center m='auto'>
                      <Heading>Seguimi!</Heading>
                  </Center>
                  <Flex marginBottom='20px'>
                      <Link className='logo-selector' marginLeft='auto' href='https://www.instagram.com/n00b_ducky' >
                          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100"
                               viewBox="0 0 48 48">
                              <radialGradient id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1" cx="19.38" cy="42.035"
                                              r="44.899"
                                              gradientUnits="userSpaceOnUse">
                                  <stop offset="0" stop-color="#fd5"></stop>
                                  <stop offset=".328" stop-color="#ff543f"></stop>
                                  <stop offset=".348" stop-color="#fc5245"></stop>
                                  <stop offset=".504" stop-color="#e64771"></stop>
                                  <stop offset=".643" stop-color="#d53e91"></stop>
                                  <stop offset=".761" stop-color="#cc39a4"></stop>
                                  <stop offset=".841" stop-color="#c837ab"></stop>
                              </radialGradient>
                              <path fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)"
                                    d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path>
                              <radialGradient id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2" cx="11.786" cy="5.54"
                                              r="29.813"
                                              gradientTransform="matrix(1 0 0 .6663 0 1.849)"
                                              gradientUnits="userSpaceOnUse">
                                  <stop offset="0" stop-color="#4168c9"></stop>
                                  <stop offset=".999" stop-color="#4168c9" stop-opacity="0"></stop>
                              </radialGradient>
                              <path fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)"
                                    d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path>
                              <path fill="#fff"
                                    d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"></path>
                              <circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle>
                              <path fill="#fff"
                                    d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"></path>
                          </svg>
                      </Link>

                      <Link className='logo-selector' href='https://www.tiktok.com/@n00b_ducky'>
                          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100"
                               viewBox="0 0 48 48">
                              <path fill="#212121" fill-rule="evenodd"
                                    d="M10.904,6h26.191C39.804,6,42,8.196,42,10.904v26.191 C42,39.804,39.804,42,37.096,42H10.904C8.196,42,6,39.804,6,37.096V10.904C6,8.196,8.196,6,10.904,6z"
                                    clip-rule="evenodd"></path>
                              <path fill="#ec407a" fill-rule="evenodd"
                                    d="M29.208,20.607c1.576,1.126,3.507,1.788,5.592,1.788v-4.011 c-0.395,0-0.788-0.041-1.174-0.123v3.157c-2.085,0-4.015-0.663-5.592-1.788v8.184c0,4.094-3.321,7.413-7.417,7.413 c-1.528,0-2.949-0.462-4.129-1.254c1.347,1.376,3.225,2.23,5.303,2.23c4.096,0,7.417-3.319,7.417-7.413L29.208,20.607L29.208,20.607 z M30.657,16.561c-0.805-0.879-1.334-2.016-1.449-3.273v-0.516h-1.113C28.375,14.369,29.331,15.734,30.657,16.561L30.657,16.561z M19.079,30.832c-0.45-0.59-0.693-1.311-0.692-2.053c0-1.873,1.519-3.391,3.393-3.391c0.349,0,0.696,0.053,1.029,0.159v-4.1 c-0.389-0.053-0.781-0.076-1.174-0.068v3.191c-0.333-0.106-0.68-0.159-1.03-0.159c-1.874,0-3.393,1.518-3.393,3.391 C17.213,29.127,17.972,30.274,19.079,30.832z"
                                    clip-rule="evenodd"></path>
                              <path fill="#fff" fill-rule="evenodd"
                                    d="M28.034,19.63c1.576,1.126,3.507,1.788,5.592,1.788v-3.157 c-1.164-0.248-2.194-0.856-2.969-1.701c-1.326-0.827-2.281-2.191-2.561-3.788h-2.923v16.018c-0.007,1.867-1.523,3.379-3.393,3.379 c-1.102,0-2.081-0.525-2.701-1.338c-1.107-0.558-1.866-1.705-1.866-3.029c0-1.873,1.519-3.391,3.393-3.391 c0.359,0,0.705,0.056,1.03,0.159V21.38c-4.024,0.083-7.26,3.369-7.26,7.411c0,2.018,0.806,3.847,2.114,5.183 c1.18,0.792,2.601,1.254,4.129,1.254c4.096,0,7.417-3.319,7.417-7.413L28.034,19.63L28.034,19.63z"
                                    clip-rule="evenodd"></path>
                              <path fill="#81d4fa" fill-rule="evenodd"
                                    d="M33.626,18.262v-0.854c-1.05,0.002-2.078-0.292-2.969-0.848 C31.445,17.423,32.483,18.018,33.626,18.262z M28.095,12.772c-0.027-0.153-0.047-0.306-0.061-0.461v-0.516h-4.036v16.019 c-0.006,1.867-1.523,3.379-3.393,3.379c-0.549,0-1.067-0.13-1.526-0.362c0.62,0.813,1.599,1.338,2.701,1.338 c1.87,0,3.386-1.512,3.393-3.379V12.772H28.095z M21.635,21.38v-0.909c-0.337-0.046-0.677-0.069-1.018-0.069 c-4.097,0-7.417,3.319-7.417,7.413c0,2.567,1.305,4.829,3.288,6.159c-1.308-1.336-2.114-3.165-2.114-5.183 C14.374,24.749,17.611,21.463,21.635,21.38z"
                                    clip-rule="evenodd"></path>
                          </svg>
                      </Link>

                      <Link className='logo-selector' marginRight='auto' href='https://www.twitch.tv/n00b_ducky/profile'>
                          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100"
                               viewBox="0 0 48 48">
                              <path fill="#7e57c2"
                                    d="M42,27.676c-3,3.441-6,6.882-9,10.324c-2.333,0-4.667,0-7,0c-2.333,2-4.667,4-7,6c-1,0-2,0-3,0	c0-2,0-4,0-6c-3.333,0-6.667,0-10,0c0-7.431,0-14.863,0-22.294C7.455,12.804,8.909,9.902,10.364,7C20.909,7,31.455,7,42,7	C42,13.892,42,20.784,42,27.676z"></path>
                              <path fill="#fafafa"
                                    d="M39,26.369c-1.667,1.877-3.333,3.754-5,5.631c-2.333,0-4.667,0-7,0c-2.333,2-4.667,4-7,6c0-2,0-4,0-6	c-2.667-0.008-5.333-0.016-8-0.024c0-7.326,0-14.651,0-21.976c9,0,18,0,27,0C39,15.456,39,20.912,39,26.369z"></path>
                              <rect width="3" height="10" x="21" y="16" fill="#7e57c2"></rect>
                              <rect width="3" height="10" x="30" y="16" fill="#7e57c2"></rect>
                          </svg>
                      </Link>
                  </Flex>
                  <Text
                      className='pink-text'
                      m='auto'
                      textAlign='center'
                      fontSize='1.2em'
                      fontWeight='normal'>@n00b_ducky</Text>
              </Container>
              <Container maxW='1020px' m='auto' paddingBottom='70px'>
                  <Center>
                      <Heading className='audiowide-regular' fontSize='2em'>Comandi Twitch</Heading>
                  </Center>

                  <SimpleGrid columns={2} spacing='60px' minChildWidth='300px'>
                      <GridItem className='pink-border' w='100%' h='100%' p='20px'>
                          <Heading>Per i subscriber</Heading>
                          <Text>SUONI</Text>
                          <ul>
                              <li><span className='blue-text'>!aldo</span> – “Non posso né scendere né salire”</li>
                              <li><span className='blue-text'>!attention</span> – “You have my attention”</li>
                              <li><span className='blue-text'>!bussano</span> – “Bussano a quest’ora del mattino…”</li>
                              <li><span className='blue-text'>!darkness</span> – ♫♪ Hello darkness my old friend… ♫ ♪</li>
                              <li><span className='blue-text'>!fakenews</span> – “You are a fake news”</li>
                              <li><span className='blue-text'>!inevitable</span> – “I am… inevitable”</li>
                              <li><span className='blue-text'>!itiswhat</span> – “It is what it is”</li>
                              <li><span className='blue-text'>!johncena</span> – “JHON CENA!!!”</li>
                              <li><span className='blue-text'>!moment</span> – “It was at this moment he knew… He fucked up”</li>
                              <li><span className='blue-text'>!quack</span> – “QUACK”</li>
                              <li><span className='blue-text'>!sexysax</span> – ♫♪ turututuuututurutuuu ♫♪</li>
                              <li><span className='blue-text'>!staccah</span> – “Ci stanno tracciando! STACCAH STACCAH!”</li>
                              <li><span className='blue-text'>!titanic</span> – ♫♪ tuuu tuuuuuu turutututuuu ♫♪</li>
                              <li><span className='blue-text'>!tuturu</span> – “TUTURU!”</li>
                              <li><span className='blue-text'>!vino</span> – “VINO!”</li>
                              <li><span className='blue-text'>!wp</span> – “Ma vai! Ma vieni! Ma chi sono! Eh, pirletti?”</li>
                          </ul>
                      </GridItem>
                      <GridItem className='blue-border' w='100%' h='100%' p='20px'>
                          <Heading>Per tutti</Heading>
                          <Text>SOCIAL</Text>
                          <ul>
                              <li><span className='pink-text'>!allmylinks</span> – Il link per accedere a tutti i miei social</li>
                              <li><span className='pink-text'>!ig</span> – Il link al mio profilo instagram</li>
                              <li><span className='pink-text'>!kofi</span> – Il link per il mio account kofi</li>
                              <li><span className='pink-text'>!wishlist</span> – Il link per la mia wishlist di Amazon</li>
                          </ul>
                          <Text>UTILITÀ</Text>
                          <ul>
                              <li><span className='pink-text'>!bttv</span> – Per conoscere le emote disponibili di BetterTTV</li>
                              <li><span className='pink-text'>!sounds</span> – Per avere la lista dei suoni in chat</li>
                          </ul>
                          <Text>ALTRI</Text>
                          <ul>
                              <li><span className='pink-text'>!accountage</span> – Da quanto tempo ho Twitch</li>
                              <li><span className='pink-text'>!date</span> – La data attuale nel mio paese</li>
                              <li><span className='pink-text'>!followed</span> – Quando hai iniziato a seguirmi</li>
                              <li><span className='pink-text'>!followers</span> – Quanti follower ho</li>
                              <li><span className='pink-text'>!following</span> – Per quanto tempo mi hai seguito</li>
                              <li><span className='pink-text'>!stats</span> – Per avere informazioni dettagliate su questo stream</li>
                              <li><span className='pink-text'>!time</span> – Per sapere che ora è nel mio paese</li>
                              <li><span className='pink-text'>!top5time</span> – Per sapere i top 5 follower che mi hanno seguito per più tempo</li>
                              <li><span className='pink-text'>!uptime</span> – Da quanto tempo sto streammando</li>
                          </ul>
                      </GridItem>
                  </SimpleGrid>
              </Container>
              <Flex>
                  <Spacer/>
                  <Image src={lines} height='50px'/>
              </Flex>
          </Box>
  );
}

export default App;
